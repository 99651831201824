import { Column } from "@antv/g2plot";
import React, { useEffect, useRef } from "react";
import s from './AuditReports.module.scss';
import { Moment } from "moment";
import { useAppSelector } from "../../../app/hooks";
import {
    selectAuditExposureStatistic,
    selectAuditIpStatistic,
    selectIsAuditExposuresLoading
} from "../../../store/reportReducer";
import { Spin } from "antd";

interface AuditExposureChartProps {
    dateForReport: Date | Moment | null;
}

const AuditExposureChart = ({ dateForReport }: AuditExposureChartProps) => {
    const currentPath = window.location.pathname
    const chartRef = useRef<HTMLDivElement>(null);
    const isLoading = useAppSelector(selectIsAuditExposuresLoading);
    const auditExposures = useAppSelector(selectAuditExposureStatistic)
    const ipStatistics = useAppSelector(selectAuditIpStatistic)
    const currentReportName = currentPath === '/audit-reports/audit-exposure' ? 'Audit Exposure Report' : 'Audit IP'

    useEffect(() => {
        // Ensure the chart only initializes when chartRef.current is not null
        if (!isLoading && chartRef.current) {
            let columnChart: any;

            if (currentReportName === "Audit Exposure Report") {
                // Chart logic for "Audit Exposure Report"
                if (auditExposures.length > 0) {
                    const data = auditExposures;
                    const colors = ["#F98E2A", "#FFFF00", "#4FCA64", "#1890FF"];

                    columnChart = new Column(chartRef.current, {
                        data,
                        xField: "domain", // Domains (categories) go on the horizontal axis (x-axis)
                        yField: "expose_count", // Values go on the vertical axis (y-axis)
                        color: ({ domain }) => {
                            const domainIndex = data.findIndex((item) => item.domain === domain);
                            return colors[domainIndex];
                        },
                        columnStyle: {
                            radius: [4, 4, 0, 0], // Rounded corners for bars
                        },
                        columnWidthRatio: 0.9,
                        xAxis: {
                            title: {
                                text: "Domain", // Label for x-axis
                                style: {
                                    fontSize: 14,
                                },
                            },
                        },
                        yAxis: {
                            title: {
                                text: "Expose Count", // Label for y-axis
                                style: {
                                    fontSize: 14,
                                },
                            },
                            min: 0, // Ensure bars always start from 0
                        },
                        tooltip: {
                            showMarkers: false, // Remove marker circles in tooltip
                        },
                        interactions: [{ type: "active-region" }], // Highlight bar on hover
                    });

                    columnChart.render();
                }
            }
            else {
                // Chart logic for grouped bars
                if (ipStatistics.length > 0) {
                    // Transform the data for grouped bars
                    const transformedData = ipStatistics.flatMap((item) => [
                        {domain: item.domain, type: "ip_domain_3_month", value: item.ip_domain_3_month},
                        {domain: item.domain, type: "ip_domain_week", value: item.ip_domain_week},
                        {domain: item.domain, type: "ip_week", value: item.ip_week},
                    ]);

                    columnChart = new Column(chartRef.current, {
                        data: transformedData,
                        isGroup: true, // Enable grouped bars
                        xField: "domain", // Domains (categories) go on the horizontal axis
                        yField: "value", // Values go on the vertical axis
                        seriesField: "type", // Group bars by type
                        color: ({type}) => {
                            const colorMap: Record<string, string> = {
                                ip_domain_3_month: "#4FCA64", // Green
                                ip_domain_week: "#F00", // Red
                                ip_week: "#FF0", // Yellow
                            };
                            return colorMap[type];
                        },
                        columnStyle: {
                            radius: [4, 4, 0, 0],
                        },
                        columnWidthRatio: 0.9,
                        xAxis: {
                            title: {
                                text: "Domain",
                                style: {
                                    fontSize: 14,
                                },
                            },
                        },
                        yAxis: {
                            title: {
                                text: "Values",
                                style: {
                                    fontSize: 14,
                                },
                            },
                            min: 0,
                        },
                        tooltip: {
                            showMarkers: false,
                            formatter: (data) => {
                                // Format the tooltip to remove underscores and capitalize words
                                const formattedType = data.type
                                    .split('_')
                                    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
                                    .join(' ');

                                return {
                                    name: formattedType, // Rename the type field
                                    value: data.value, // Keep the value as it is
                                };
                            },
                        },
                        interactions: [{type: "active-region"}],
                        legend: false, // Disable the legend (removes buttons at the top)
                    });

                    columnChart.render();
                }
            }

            return () => {
                if (columnChart) columnChart.destroy();
            };
        }
    }, [isLoading, auditExposures, currentReportName, ipStatistics]);

    return (
        <div style={{
            width: '100%'
        }}>
            {
                currentReportName === "Audit Exposure Report"
                ?
                    <h1 className={s.title}>
                        Exposure count by {dateForReport ? `${dateForReport.toISOString().split("T")[0]}` : "N/A"} for the last 7 days
                    </h1>
                    :
                    <h1 className={s.title}>
                        IP count by {dateForReport ? `${dateForReport.toISOString().split("T")[0]}` : "N/A"}
                    </h1>
            }

            {isLoading ? (
                <div className={s.spinnerContainer}>
                    <Spin size="large" /> {/* Ant Design spinner */}
                </div>
            ) : (
                <div ref={chartRef} style={{ width: "100%", height: "400px" }}></div>
            )}
        </div>
    );
};

export default AuditExposureChart;



