import React, {useEffect, useState} from 'react';
import PageWithSidebar from "../../PageWithSidebar/PageWithSidebar";
import PageTitle from "../../common/PageTitle/PageTitle";
import ReportForm from "../ReportForm/ReportForm";
import SuccessMessage from "../../common/SuccessMessage/SuccessMessage";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {
    GetAuditReportsExposuresStatisticThunk,
    GetAuditReportsIpDomainStatisticThunk, selectAuditIpStatistic
} from "../../../store/reportReducer";
import {PickerType} from "../../../types/dateTypes";
import AuditExposureChart from "./AuditExposureChart";
import {Moment} from "moment";
import s from './AuditReports.module.scss'

const AuditReportsPage = () => {
    return (
        <PageWithSidebar>
            <AuditReportsPageComponent/>
        </PageWithSidebar>
    )
}

const AuditReportsPageComponent = () => {
    const currentPath = window.location.pathname
    const currentReportName = currentPath === '/audit-reports/audit-exposure' ? 'Audit Exposure Report' : 'Audit IP Report'
    const dispatch = useAppDispatch()
    const [isExported, setIsExported] = useState(false)
    const reportTypeData = {}
    const [dateType, setDateType] = useState<PickerType>('currentWeek')
    const today = new Date()
    const [dateForReport, setDateForReport] = useState<Date | Moment | null>(today)

    useEffect(() => {
        if (currentReportName === 'Audit Exposure Report'){
            dispatch(GetAuditReportsExposuresStatisticThunk({domain_code: 'OR', start_period: today}))
        }
        else{
            dispatch(GetAuditReportsIpDomainStatisticThunk({domain_code: 'OR', start_period: today}))
        }
    }, [dispatch])


    return (
        <div style={{
            padding: '40px 100px 50px 30px'
        }}>
            <PageTitle title={currentReportName}
                       backLink='/audit-reports'
                       isBackButton={true}
                       backLinktext={'Audit Reports'}
                       left={'35px'}
                // top={'153px'}
            />
            <div className={s.contentWrapper} style={{
                marginTop: '50px'
            }}>
                <div style={{
                    width: '100%'
                }}>
                    <ReportForm
                        setIsExported={setIsExported}
                        isAudit={true}
                        reportTypeData={reportTypeData}
                        isFraud={false}
                        reportName={currentReportName}
                        fraudValueCount={0}
                        dateType={dateType}
                        setDateType={setDateType}
                        setDateForReport={setDateForReport}
                        auditReportDate={dateForReport}
                    />
                </div>
                <AuditExposureChart dateForReport={dateForReport}/>
            </div>

            <SuccessMessage
                text='Your report has been successfully exported'
                onOk={() => {
                    setIsExported(false)
                }}
                isOpen={isExported}
            />
        </div>
    );
};

export default AuditReportsPage;