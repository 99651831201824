import ReportPage from '../components/ReportPage/ReportPage'
import ControlPanel from '../components/ControlPanel/ControlPanel'
import SignIn from '../components/SignIn/SignIn'
import reportData from '../helpers/reportData'
import ParametersPage from '../components/ParametersPage/ParametersPage'
import MarketingReports from '../components/ParametersPage/MarketingReports/MarketingReports'
import DisclaimerInfo from '../components/ParametersPage/DisclaimerInfo/DisclaimerInfo'
import Requirements from "../components/RequirementsPage/Requirements";
import Properties from "../components/PropertiesPage/Properties";
import TemplatesForEmail from "../components/ParametersPage/TemplatesForEmail/TemplatesForEmail";
import EmailTemplate from "../components/ParametersPage/EmailTemplate/EmailTemplate";
import Home from "../components/Home/Home";
import AllProperties from "../components/AllProperties/AllProperties";
import AuditGrid from "../components/AuditGrid/AuditGrid";
import FraudReportsPage from "../components/ReportPage/FraudReports/FraudReportsPage";
import FraudReports from "../components/ReportPage/FraudReports/FraudReports";
import AddressForm from "../components/AddressForm/AddressForm";
import Notifications from "../components/Notifications/Notifications";
import ActivitiesGrid from "../components/ActivitiesGrid/ActivitiesGrid";
import OrganizationActivitiesGrid from "../components/OrganizationActivities/OrganizationActivitiesGrid";
import OrganizationActivitiesProperties
  from "../components/OrganizationActivities/OrganizationActivitiesProperties/OrganizationActivitiesProperties";
import SoldPropertiesPage from "../components/SoldPropertiesPage/SoldPropertiesPage";
import PropertiesMap from '../components/PropertiesMap/PropertiesMap'
import SignatureComponent from "../components/SignatureComponent/SignatureComponent";
import Terms from "../components/Terms/Terms";
import PrivacyPolicy from '../components/Privacy/PrivacyPolicy'
import AttributesCode from "../components/AttributesCode/AttributesCode";
import BrokerageReqs from "../components/BrokerageReqs/BrokerageReqs";
import ReportsAgents from "../components/ReportsAgents/ReportsAgents";
import Administration from "../components/ParametersPage/Administration/Administration";
import ReqsSearchedByContacts from "../components/RequirementsPage/ReqsSearchedByContacts/ReqsSearchedByContacts";
import CreateReq from "../components/RequirementsPage/CreateReq/CreateReq";
import CreateContactForReq from "../components/RequirementsPage/CreateContactForReq/CreateContactForReq";
import FaQs from "../components/FAQs/FAQs";
import EditProperty from "../components/PropertiesPage/EditProperty/EditProperty";
import Protocols from "../components/Protocols/Protocols";
import AuditReportsPage from "../components/ReportPage/AuditReports/AuditReportsPage";
import AuditReports from '../components/ReportPage/AuditReports/AuditReports'


const routes: RouteType[] = [
  {path: '/', component: Home, auth: true},
  {path: '/google/authorization', component: Home, auth: true},
  {path: '/requirements', component: Requirements, auth: true},
  {path: '/control-panel', component: ControlPanel, auth: true},
  {path: '/sign-in', component: SignIn, auth: false},
  {path: '/control-panel/activity-report', component: ReportPage, auth: true, props: {reportTypeData: reportData['/control-panel/activity-report']}},
  {path: '/control-panel/agent-last-contact', component: ReportPage, auth: true, props: {reportTypeData: reportData['/control-panel/agent-last-contact']}},
  {path: '/control-panel/property-last-contact', component: ReportPage, auth: true, props: {reportTypeData: reportData['/control-panel/property-last-contact']}},
  {path: '/control-panel/valuated-properties-last-contact', component: ReportPage, auth: true, props: {reportTypeData: reportData['/control-panel/valuated-properties-last-contact']}},
  {path: '/control-panel/requirement-last-contact', component: ReportPage, auth: true, props: {reportTypeData: reportData['/control-panel/requirement-last-contact']}},
  {path: '/fraud-reports', component: FraudReports, auth: true},
  {path: '/fraud-reports/max-props-in-one-email', component: FraudReportsPage, auth: true},
  {path: '/fraud-reports/max-props-in-contacts', component: FraudReportsPage, auth: true},
  {path: '/fraud-reports/max-props-in-organizations', component: FraudReportsPage, auth: true},
  {path: '/audit-reports/audit-exposure', component: AuditReportsPage, auth: true},
  {path: '/audit-reports/audit-ip', component: AuditReportsPage, auth: true},
  {path: '/audit', component: AuditGrid, auth: true},
  {path: '/audit-reports', component: AuditReports, auth: true},
  {path: '/parameters', component: ParametersPage, auth: true},
  {path: '/parameters/marketing-reports', component: MarketingReports, auth: true},
  {path: '/parameters/disclaimer-info', component: DisclaimerInfo, auth: true},
  {path: '/parameters/templates-for-email', component: TemplatesForEmail, auth: true},
  {path: '/parameters/templates-for-email/email-template', component: EmailTemplate, auth: true},
  {path: '/requirements/properties', component: Properties, auth: true},
  {path: '/reqs-searched-by-contacts', component: ReqsSearchedByContacts, auth: true},
  {path: '/properties', component: AllProperties, auth: true},
  {path: '/contactProperties', component: AllProperties, auth: true},
  {path: '/properties/brokerage/reqs', component: BrokerageReqs, auth: true},
  {path: '/test', component: AddressForm, auth: true},
  {path: '/notifications', component: Notifications, auth: true},
  {path: '/activitiesGrid', component: ActivitiesGrid, auth: true},
  {path: '/organizationActivities', component: OrganizationActivitiesGrid, auth: true},
  {path: '/organizationActivitiesProperties', component: OrganizationActivitiesProperties, auth: true},
  {path: '/mapProperties', component: PropertiesMap, auth: true},
  {path: '/soldPropertiesPage/sold/*', component: SoldPropertiesPage, auth: false},
  {path: '/signature', component: SignatureComponent, auth: false},
  {path: '/termsAndConditions', component: Terms, auth: false},
  {path: '/privacyPolicy', component: PrivacyPolicy, auth: false},
  {path: '/parameters/create-activity-codes', component: AttributesCode, auth: true},
  {path: '/parameters/report-agents-order', component: ReportsAgents, auth: true},
  {path: '/parameters/administration', component: Administration, auth: true},
  {path: '/requirements/create-req', component: CreateReq, auth: true},
  {path: '/requirements/create-req-contact', component: CreateContactForReq, auth: true},
  {path: '/requirements/edit-req/:id', component: CreateReq, auth: true},
  {path: '/requirements/edit-req-cold-offer/:id', component: CreateContactForReq, auth: true},
  {path: '/properties/:id', component: EditProperty, auth: true},
  {path: '/faqs', component: FaQs, auth: true},
  {path: '/protocols', component: Protocols, auth: true},
]

interface RouteType {
  path: string
  component: React.FC<any>
  auth: boolean
  props?: object
  breadcrumb?: string | null
}

export default routes
