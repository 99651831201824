import React, { useEffect } from 'react';
import PageWithSidebar from "../../PageWithSidebar/PageWithSidebar";
import {ReactComponent as AuditExposureIcon} from "../../../img/icons/auditExposure.svg";
import {ReactComponent as AuditIpIcon} from "../../../img/icons/auditIp.svg";
import classes from "../../ControlPanel/ControlPanel.module.css";
import {Link} from "react-router-dom";


const AuditReports = () => {
    return (
        <PageWithSidebar>
            <AuditReportsComponent/>
        </PageWithSidebar>
    )
}

const AuditReportsComponent = () => {
    const panelItems = [
        {id: 1, title: 'Audit Exposure', img: AuditExposureIcon, link: '/audit-reports/audit-exposure'},
        {id: 2, title: 'Audit IP',  img: AuditIpIcon, link: '/audit-reports/audit-ip'},
    ]

    return (
        <div className={classes.wrapper}>
            <div className={classes.contentWrapper}>
                <div className={classes.titleWrapper}>
                    <h1>
                        Audit Reports
                    </h1>
                </div>

                <div className={classes.panelWrapper} style={{
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                    {panelItems.map((item, index) => (
                        <Link to={item.link} className={`${classes.panelItem} ${classes['item' + index]}`} key={item.id}>
                            <item.img />
                            {item.title}
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default AuditReports;